@import '../../../theme/index';

.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  &.active {
    padding-bottom: 20px;
  }

  .textBlock {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $balticSea;
  }
}

.container::-webkit-scrollbar {
  width: 0px;
}
