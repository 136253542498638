@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/proMoistureLine.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 19%;
    margin-top: 50%;
    position: absolute;

    .popover {
      @include respond-to(desktop) {
        bottom: -30px;
      }
      @include respond-to(largeDesktop) {
        bottom: 22px;
      }
    }
  }

  .secondPopover {
    margin-left: 43%;
    margin-top: 36%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        margin-left: -67px;
      }
      @include respond-to(desktop) {
        margin-left: -24px;
      }
      @include respond-to(largeDesktop) {
        margin-left: 0px;
      }
    }
  }

  .thirdPopover {
    margin-left: 64%;
    margin-top: 57%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -24px;
      }
      @include respond-to(desktop) {
        bottom: -35px;
      }
      @include respond-to(largeDesktop) {
        bottom: 22px;
      }
    }
  }

  .fourthPopover {
    margin-left: 72%;
    margin-top: 25%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -24px;
      }
      @include respond-to(desktop) {
        bottom: -35px;
      }
      @include respond-to(largeDesktop) {
        bottom: 22px;
      }
    } 
  }
}

