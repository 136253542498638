@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/heartleafBlemish.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 14%;
    margin-top: 30%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -35px;
      }
      @include respond-to(desktop) {
        bottom: -62px;
      }
      @include respond-to(largeDesktop) {
        bottom: 22px;
      }
    }
  }

  .secondPopover {
    margin-left: 35%;
    margin-top: 39%;
    position: absolute;
  }

  .thirdPopover {
    margin-left: 62%;
    margin-top: 45%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        margin-left: -60px;
      }
      @include respond-to(desktop) {
        margin-left: -35px;
      }
      @include respond-to(largeDesktop) {
        margin-left: 22px;
      }
    }
  }

  .fourthPopover {
    margin-left: 82%;
    margin-top: 51%;
    position: absolute;
  }

  .fifthPopover {
    margin-left: 75%;
    margin-top: 20%;
    position: absolute;
    .popover {
      @include respond-to(tablet) {
        margin-bottom: -100px;
      }
    }
  }
}
