@import '../../../../theme/index';

.textBlock {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $balticSea;
}

.container {
  background-color: $white50;
  box-sizing: border-box;
  padding: 25px 34px 24px 26px;
  margin-top: 10px;
  border-radius: 20px;

  .title {
    display: flex;
    justify-content: space-between;

    .textTitle {
      @extend .textBlock;
      font-weight: 600;
      color: $butterflyBush;
    }

    .btnClose {
      width: 22px;
      height: 22px;
      transform: rotate(180deg);
      display: none;
      >svg>path {
        fill: none;
      }

      &.active {
        display: flex;
      }
    }
  }

  .descriptionApplication {
    display: none;
    @extend .textBlock;
    margin-top: 4px;
    &.active {
      display: block;
    }
  }
}
