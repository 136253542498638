@import "../../theme/index";

.menuSvg {
  background-color: $alto50;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include respond-to(mobile) {
    display: flex;
  }

  @include respond-to(tablet) {
    display: none;
  }
}

.buttonColor {
  color: $balticSea;
}

.button {
 // core 
  &Portage{
    @extend .buttonColor;
    &:hover {
      color: $portage;
    }
  }

  // WormMood
  &AquaForest{
    @extend .buttonColor;
    &:hover {
      color: $aquaForest;
    }
  }

  //HeartLeaf
  &GreenSmoke{
    @extend .buttonColor;
    &:hover {
      color: $greenSmoke;
    }
  }

  // pro M
  &Indigo{
    @extend .buttonColor;
    &:hover {
      color: $lightPortage;
    }
  }

  // boime
  &NewYorkPink{
    @extend .buttonColor;
    &:hover {
      color: $newYorkPink;
    }
  }

  // honey 
  &Ronchi{
    @extend .buttonColor;
    &:hover {
      color: $ronchi;
    }
  }
}

.menu {
  @include respond-to(mobile) {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: $white;
    display: none;

    &.active {
      display: block;
    }

    .header {
      width: 100%;
      height: 75px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 14px 10px 34px;
      box-sizing: border-box;

      .logo {
        width: 119px;
        margin-top: 7px;
        display: flex;
        align-items: center;
        svg {
          width: 100%;
          height: inherit;
        }
      }
      .btnCLose {
        border-radius: 10px;
      }
    }

    .line {
      margin-top: 10px;
      height: 0px;
      width: 100%;
      border: 1px solid $mercury;
    }

    .content {
      padding: 25px 15px 25px 15px;
      box-sizing: border-box;

      .title {
        color: $mineShaft;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        opacity: 0.5;
        margin-left: 18px;
      }

      .linksWrapper {
        margin-top: 5px;
        display: flex;
        flex-direction: column;

        .link {
          padding-left: 18px;
          font-weight: 500;
          font-size: 16px;
          line-height: 23px;
          padding-top: 14px;
          padding-bottom: 14px;
          text-decoration: none;
          display: flex;
          box-sizing: border-box;
          border: 1px solid transparent;
          border-radius: 8px;

          &:hover {
            border: 1px solid $alto;
          }

          &.active {
            border: 1px solid $alto;
          }
        }
      }
    }
  }
  @include respond-to(tablet) {
    display: none;

    &.active {
      display: none;
    }
  }


  .lineSecond {
    margin: 25px 0px;
    height: 0px;
    width: 100%;
    border: 1px solid $alto;
  }

  .additionalMenu {
    flex-direction: column;
    display: flex;
    padding: 0px 15px;
    box-sizing: border-box;

    .link {
      text-decoration: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      color: $balticSea;
      align-items: center;
      padding: 0px 10px;

      &:hover {
        color: $matrix;
      }

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }
}
