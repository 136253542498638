@import '../../../theme/index';

.preloader {
  height: 100vh;
  padding-top: 70px;

  @include respond-to(tablet) {
    display: none;
  }
}

.container {
  display: block;
  background: url('../../../assets/images/fraijourMain/main/mainBackgroundMobile.jpg') center center no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  height: 90vh;
  min-height: 652px;
  padding-top: 70px;
  animation: showWithOpacity 0.3s ease-in-out;
  box-sizing: border-box;

  @include respond-to(tablet) {
    display: none;
  }
}

.wrapperBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 17px;

  .arrowToDown {
    width: 16px;
    height: 24px;
  }
}

.btn {
  width: fit-content;
  height: 50px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0px 25px;
  margin: 31px auto;
  font-size: 14px;
  font-weight: 600;
}