@import '../../../theme/index';

.preloader {
  box-sizing: border-box;

  @include respond-to(mobile) {
    display: none;
  }
  @include respond-to(tablet) {
    padding-top: 80px;
    height: 680px;
    border-radius: 0px;
    display: block;
  }
  @include respond-to(desktop) {
    padding-top: 70px;
    min-height: 700px;
    max-height: 900px;
    height: 700px;
    border-radius: 0px 0px 25px 25px;
  }
  @include respond-to(largeDesktop) {
    padding-top: 80px;
    height: 900px;

  }
}

.container {
  background: url('../../../assets/images/fraijourMain/main/mainBackground.jpg') center center no-repeat fixed;
  animation: showWithOpacity 0.3s ease-in-out;
  background-size: cover;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  @include respond-to(mobile) {
    display: none;
  }
  @include respond-to(tablet) {
    padding-top: 80px;
    min-height: 680px;
    max-height: 700px;
    border-radius: 0px;
    display: block;
  }
  @include respond-to(desktop) {
    padding-top: 70px;
    min-height: 700px;
    max-height: 900px;
    border-radius: 0px 0px 25px 25px;
  }
  @include respond-to(largeDesktop) {
    padding-top: 80px;
    min-height: 900px;
    max-height: 1080px;
  }
}

