@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/yuzuHoneyLine.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 8%;
    margin-top: 30%;
    position: absolute;
  }

  .secondPopover {
    margin-left: 24%;
    margin-top: 25%;
    position: absolute;

    .popover {
      @include respond-to(desktop) {
        margin-left: 11px;
        bottom: -6px;
      }
    }
  }

  .thirdPopover {
    margin-left: 38%;
    margin-top: 31%;
    position: absolute;

    .popover {
      bottom: 10px;
    }
  }

  .fourthPopover {
    margin-left: 47%;
    margin-top: 36%;
    position: absolute;
  }

  .fifthPopover {
    margin-left: 58%;
    margin-top: 36%;
    position: absolute;
  }

  .sixthPopover {
    margin-left: 79%;
    margin-top: 26%;
    position: absolute;
    .popover {
      @include respond-to(desktop) {
        bottom: -60px;
      }
    }
  }

  .seventhPopover {
    margin-left: 84%;
    margin-top: 34%;
    position: absolute;
  }
}
