@import '../../../../theme/index';

.btnRollUpFixedWrapper {
  position: fixed;
  width: 100%;
  left: 0px;
  display: flex;
  justify-content: center;
  bottom: 0px;
  padding-bottom: 42px;
  background: linear-gradient(transparent, #E8E8F4);
  &.hidden {
    display: none;
  }

  .btnRollUpFixed {
    width: 142px;
    height: 50px;
    justify-content: center;
    background-color: $white;
  }
}

.textBtnRollUp {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-right: 5px;
}

.btnRollUpWrapper {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  .btnRollUp {
    display: flex;
    width: 160px;
    height: 50px;
    background-color: $white;
  }
}
