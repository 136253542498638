@import '../../../../theme/index';

.preloader {
  width: 100%;
  bottom: 0;
  position: absolute;
  z-index: 10;
  height: 100%;
  border-radius: 0px;
  background: transparent;

  @include respond-to(desktop) {
    border-radius: 0px 0px 25px 25px;
  }
}

.shadowBox {
  width: 100%;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  position: absolute;
  z-index: 10;
  animation: show-box 0.5s ease-in-out;
  animation-fill-mode: forwards;
  display: block;

  @include respond-to(tablet) {
    border-radius: 0px;
  }
  @include respond-to(desktop) {
    border-radius: 0px 0px 25px 25px;
  }

}

@keyframes show-box {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  100% {
      display: block;
      opacity: 0.8;
  }
}
