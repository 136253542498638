@import "../../theme/index";

.container {
  display: none;
  width: 100%;
  justify-content: space-between;

  @include respond-to(tablet) {
    display: flex;
    height: 555px;
  }

  @include respond-to(desktop) {
    height: 675px;
  }

  @include respond-to(largeDesktop) {
    height: 875px;
  }

  .sidebar {
    .textBlock {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      border-bottom: 1px solid $mineShaft25;
    }

    .title {
      @extend .textBlock;
      color: $mineShaft50;
      padding-bottom: 18px;
      margin-bottom: 14px;
      display: none;

      @include respond-to(desktop) {
        display: block;
      }
    }

    .productsBlock {
      width: 238px;

      @include respond-to(desktop) {
        margin-left: 14px;
        width: 401px;
      }
    }
  }

  .allProducts {
    position: relative;
    display: flex;
    justify-content: center;

    @include respond-to(tablet) {
      width: 719px;
      height: 555px;
      margin-left: 12px;
    }

    @include respond-to(desktop) {
      width: 875px;
      height: 675px;
      margin-left: 21px;
    }

    @include respond-to(largeDesktop) {
      width: 1140px;
      height: 875px;
      margin-left: 59px;
    }

    .buttonOpenLine {
      position: absolute;
      bottom: 25px;
      display: block;
      padding: 7px 16px;
      min-height: 16px;
      font-size: 13px;
      line-height: 16px;
      border-radius: 25px;
      width: 144px;

      @include respond-to(desktop) {
        display: none;
      }
    }
  }
}

.containerMobile {
  height: 660px;
  width: 376px;
  padding-top: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @include respond-to(tablet) {
    display: none;
  }

  .allProducts {
    width: 365px;
    height: 264px;
    position: relative;
    display: flex;
    justify-content: center;

    .buttonOpenLine {
      position: absolute;
      bottom: 15px;
      width: fit-content;
      min-height: 16px;
      padding: 7px 16px;
      text-align: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
    }
  }
}