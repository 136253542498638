@import "../../theme/index";

.error {
  padding: 20vh;
}

.main {
  background-color: $white;
  animation: showWithOpacity 0.3s ease-in-out;

  .section {
    display: flex;
    justify-content: center;
    margin-bottom: 90px;

    @include respond-to(mobile) {
      margin-bottom: 224px;
    }

    @include respond-to(desktop) {
      margin-bottom: 163px;
    }

    @include respond-to(largeDesktop) {
      margin-bottom: 178px;
    }
  }

  .brand {
    display: flex;
    justify-content: center;

    @include respond-to(tablet) {
      padding: 0px 32px;
    }

    @include respond-to(desktop) {
      padding: 0px 57px 0px 57px;
    }

    @include respond-to(largeDesktop) {
      padding: 0px 260px 0px 70px;
      justify-content: flex-end;
    }
  }

  .lines {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}