@import "../../theme/index";

.roundBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  padding: 0;
  
  @include respond-to(tablet) {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }

  @include respond-to(desktop) {
    width: 60px;
    height: 60px;
    border-radius: 30px;
  }

  &__border {
    border: 1px solid $white;
    background: rgba(0,0,0, 0);
  }

  &__background {
    border: none;
    background-color: $white;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    border-radius: 27px;
    background-color: $white;

    @include respond-to(tablet) {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      > svg {
        width: 16px;
        height: 16px;
      }
    }
  
    @include respond-to(desktop) {
      width: 54px;
      height: 54px;
      border-radius: 27px;
      > svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  // Primary variant styles
  &.roundBtnPrimary:hover{
    > div > svg > path {  
      fill: $portage;
    }
  }

  // Primary light styles
  &.roundBtnLight:hover {
    .roundBtn__border  {
      border: 1px solid $whisper;
    }

    .roundBtn__content  {
      background-color: $whisper;
    }
  }
}
