@import "../../theme/index";

.container {

  @include respond-to(mobile) {
    position: fixed;
    z-index: 1000;
    top: 0px;
    left: 0px;
    background-color: $santasGray;
    width: 100%;
    height: 100%;

    .wrapper {
      position: relative;
    }

    .header {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 75px;
      padding: 21px 27px 0px 28px;
      box-sizing: border-box;

      .btnClose {
        width: 42px;
        height: 42px;
        margin: 9px 0px 0px auto;
      }
    }
  }
  @include respond-to(tablet) {
    display: none;
    &.active {
      display: none;
    }
  }
}