@import "../../../theme/index";

.popoverContainerLeft {
  position: absolute;
  margin-left: -21px;
  margin-top: 3px;
  z-index: 1000;

  @include respond-to(desktop) {
    margin-left: -17px;
    margin-top: 0px;
  }

  .popover {
    position: absolute;
    right: 25px;
    bottom: 22px;
  }
}

.popoverContainerRight {
  position: absolute;
  margin-left: 40px;
  z-index: 1000;

  @include respond-to(desktop) {
    margin-left: 54px;
  }

  .popover {
    position: absolute;
    margin-left: 22px;
    bottom: 22px;
  }
}

.wrapper {
  position: relative;
  margin-left: -5px;
  margin-top: -27px;
  height: 35px;
  width: 35px;

  @include respond-to(desktop) {
    margin-top: -20px;
  }

  .line {
    height: 100%;
    width: 100%;
  }

  .content {
    background-color: $white;
    border-radius: 30px;
  }
}
