@import "../../../theme/index";

.linesList {
  display: none;
  flex-direction: column;
  width: 207px;
  border: 1px solid $silver;
  border-radius: 15px;
  padding: 21px 15px 22px 16px;
  margin-bottom: 22px;

  @include respond-to(tablet) {
    display: flex;
  }

  @include respond-to(desktop) {
    padding: 24px 30px 22px 30px;
    margin-bottom: 19px;
    width: 370px;
    margin-bottom: 23px;
  }

  @include respond-to(largeDesktop) {
    padding: 25px 30px 30px 30px;
  }

  .title {
    color: $mineShaft50;
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    @include respond-to(desktop) {
      margin-bottom: 2px;
    }
  }

  .lines {
    display: flex;
    flex-direction: column;
    color: $mineShaft;
  }

  .lineActive {
    // core 
    &Portage {
      color: $portage;
    }
  
    // WormMood
    &AquaForest {
      color: $aquaForest;
    }
  
    //HeartLeaf
    &GreenSmoke {
      color: $greenSmoke;
    }
  
    // pro M
    &Indigo {
      color: $lightPortage;
    }
  
    // boime
    &NewYorkPink {
      color: $newYorkPink;
    }
  
    // honey 
    &Ronchi {
      color: $ronchi;
    }
  }

  .line {
    border: none;
    background: none;
    width: fit-content;
    padding: 0;
    font-weight: 600;
    line-height: 20px;
    font-size: 14px;
    text-transform: uppercase;

    // core 
    &Portage:hover {
      color: $portage;
    }

    // WormMood
    &AquaForest:hover {
        color: $aquaForest;
    }

    //HeartLeaf
    &GreenSmoke:hover {
      color: $greenSmoke;
    }

    // pro M
    &Indigo:hover {
      color: $lightPortage;
    }

    // boime
    &NewYorkPink:hover {
      color: $newYorkPink;
    }

    // honey 
    &Ronchi:hover {
      color: $ronchi;
    }

  }

  .lineBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    height: 20px;
    position: relative;

    .lastLine {
      width: 9px;
      height: 17px;
      border-bottom-left-radius: 5px;
      border-left: 1px solid $silver;
      border-bottom: 1px solid $silver;
      position: absolute;
      left: -21px;
      bottom: 50%;
    }

    @include respond-to(desktop) {
      margin-bottom: 3px;
      height: 30px;
    }
  }

  .secondLine {
    margin-bottom: 0px;
    margin-left: 21px;
  }

  .buttonShow {
    display: none;
    padding: 7px 16px;
    min-height: 16px;
    font-size: 13px;
    line-height: 16px;
    border-radius: 25px;
    width: fit-content;

    @include respond-to(desktop) {
      display: block;
    }
  }
}

.linesListMobile {
  display: flex;
  justify-content: space-between;
  width: 365px;
  margin-bottom: 15px;

  @include respond-to(tablet) {
    display: none;
  }

  .btnIcon {
    width: 14px;
    height: 8px;
  }

  .headerMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .titleMobile {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $mineShaft50;
    text-align: center;
    margin-bottom: 4px;
  }

  .textMobile {
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }
}

.buttonNavigate {
  width: 42px;
  height: 42px;
}

.evasBtnArrow {
  width: 20px;
  margin-top: 4px;
  margin-left: 12px;
}