@import '../../theme/index';

.preloader {
  width: 100%;
  background: linear-gradient(to bottom right, $white, #DBDAEE, $white);
  background-size: 400% 400%;
  animation: gradient 5s linear infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
