.backgroundImg {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bottleImg {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: 68px auto 0px auto;
  transform: rotate(6deg);
  width: 139px;
}
