@import "../../theme/index";

.header {
  width: 100%;
  max-width: 2000px;
  height: 50px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  background-color: rgba(255,255,255, 0.25);
  position: relative;
  margin: 0 auto;

  .searchLinksWrapper {
    width: auto;
    display: flex;

    .additionalMenu {
      display: none;
      align-items: center;
      margin-right: 10px;

      @include respond-to(tablet) {
        display: flex;
      }

      .link {
        text-decoration: none;
        font-weight: 700;
        line-height: 16px;
        color: $balticSea;
        align-items: center;
        padding: 0px 10px;

        &:hover {
          color: $matrix;
        }

        &:first-child {
          border-right: 1px solid $black04;
        }
      }
    }
  }

  @include respond-to(tablet) {
    padding: 15px 0px;
  }

  @include respond-to(desktop) {
    padding: 10px 0px;
  }

  @include respond-to(largeDesktop) {
    padding: 15px 0px;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding:0px 15px;
    @include respond-to(tablet) {
      padding:0px 32px;
    }
    @include respond-to(desktop) {
      padding:0px 57px; 
    }
    @include respond-to(largeDesktop) {
      padding: 0px 260px;
    }
  }

  .logo {
    width: 70px;
    height: 52px;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    width: 179px;
    margin: 10px 0px;
    height: 50px;
    padding: 0px 10px;

    @include respond-to(mobile) {
      width: 193px;
      height: 28px;

      >.button {
        background-color: rgba(255,255,255, 0);
      }
    }
    @include respond-to(tablet) {
      width: 69px;
    }
    @include respond-to(desktop) {
      width: 149px;
    }
    @include respond-to(largeDesktop) {
      width: 179px;
    }
  }

  .btnSearch {
    padding: 13px;
    border-radius: 10px;

    &:hover {
      > svg > path {  
        fill: $mediumPurple;
      }
    }
  }

  .button {
    font-weight: 700;
    line-height: 16px;
    font-size: 12px;
    color: $balticSea;
    border: none;
    background-color: rgba(255,255,255, 0.30);
    display: inline-flex;
    align-items: center;
    &:active {
      color: $mediumPurple;
      background: radial-gradient(ellipse at bottom, rgba(255,255,255, 0.8), transparent);
    }
    &:hover:not(:disabled) {
      color: $mediumPurple;
    }
  }
}
