@import "../../theme/index";

.header {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  z-index: 10;
  background-color: rgba(255,255,255, 0.60);
  position: relative;
  @include respond-to(tablet) {
    height: 80px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 15px;
    @include respond-to(tablet) {
      width: 1400px;
    }
  }

  .centerStyle {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .btnSearch {
    @extend .centerStyle;
    width: 40px;
    height: 40px;
  }

  .item {
    @extend .centerStyle;
    color: $balticSea;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    height: 40px;
  }

  .dropdownArrow {
    margin-left: 5px;
  }

  .leftSide {
    width: 396px;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 10px;
    display: none;
    @include respond-to(tablet) {
      display: flex;
    }
  }

  .rightSide {
    width: 392px;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 10px;
    display: none;
    @include respond-to(tablet) {
      display: flex;
    }
  }
}
