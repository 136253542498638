$black: #000000;
$black04: rgba(4, 4, 4, 0.06);
$black30: rgba(4, 4, 4, 0.3);
$codGray20: rgba(30, 30, 30, 0.20);
$balticSea: #232226;
$balticSea40: rgba(35, 34, 38, 0.40);
$mineShaft: #2c2c2c;
$mineShaft50: rgba(44, 44, 44, 0.5);
$mineShaft25: rgba(44, 44, 44, 0.25);
$santasGray: #9f9fae;
$dustyGray: #959595;
$dolphin: #696774;
$woodsmoke : #101012;
$athensGray: #f0f0f2;
$concrete: #F2F2F2;
$white: #fff;
$white25: rgba(255, 255, 255, 0.25);
$white30: rgba(255, 255, 255, 0.3);
$white50: rgba(255, 255, 255, 0.5);
$outLine: rgba(255, 255, 255, 0.46);
$chetwodeBlue: #817ede;
$mediumPurple: #736fde;
$purpleHeart: #6821B1;
$blueGem: #53139C;
$violet: #210D40;
$studio: #8E4BB7;
$butterflyBushLight: #AEACE7;
$boxShadow: rgb(183, 183, 183);
$creamCan: #F0D25F;
$flax: #ECD67D;
$alto: #d9d9d9;
$silver: #cacaca;
$prelude: rgba(206, 201, 232);
$prelude65: rgba(206, 201, 232, 0.65);
$heliotrope: #F582FF;
$whiteLilac: #F5F2FB;
$whisper: #E9E5F1;

//biome
$newYorkPink: #D97D74;
$newYorkPinkHover: rgba(217, 125, 116, 0.3);
$matrix: #BB6259;

//core
$portage: #9996ef;
$butterflyBush: #54529C;
$portageHover: rgba(153, 150, 239, 0.3);

//pro M
$lightPortage: #7999E2;
$indigo: #617EC1;

//WormMood
$aquaForest: #609A6F;
$aquaForestHover: rgba(96, 154, 111, 0.3);
$killarney: #3C7049;

//HeartLeaf
$greenSmoke: #A0B264;
$greenSmokeHover: rgba(160, 178, 100, 0.3);
$asparagus: #7F8F49;

//honey
$ronchi:#E8BF56;
$ronchiHover: rgba(232, 191, 86, 0.3);
$roti: #C7A03C;

$mulledWine: #4d4b6c;
$alto50: rgba(223, 223, 223, 0.5);
$mercury: #E9E9E9;
$whiteLilac: #F5F2FB;
$studio: #8E4BB7;
$eminence: #6D2F89;
$snuff: #E1DAEE;
$dingley: #668354;
$mountainMist: #A29BA8;
$wisteria: #A274B7;
