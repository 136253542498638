@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/biomeLactoLine.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 19%;
    margin-top: 49%;
    position: absolute;
  }

  .secondPopover {
    margin-left: 41%;
    margin-top: 22%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -26px;
      }
      @include respond-to(desktop){
        margin-left: 28px;
        bottom: -49px;
      }
      @include respond-to(largeDesktop) {
        margin-left: 22px;
        bottom: 12px;
      }
    }
  }

  .thirdPopover {
    margin-left: 56%;
    margin-top: 40%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        margin-left: -38px;
      }
      @include respond-to(desktop) {
        margin-left: 22px;
      }
    }
  }

  .fourthPopover {
    margin-left: 68%;
    margin-top: 20%;
    position: absolute;
    .popover {
      @include respond-to(tablet) {
        margin-bottom: -200px;
      }
    }
  }

  .fifthPopover {
    margin-left: 78%;
    margin-top: 52%;
    position: absolute;
    .popover {
      @include respond-to(tablet) {
        margin-bottom: -100px;
      }
    }
  }
}
