@import "../../../theme/index";

.item {
  position: relative;
  width: 100%;
  padding-bottom: 68%;
  cursor: pointer;
}

.title {
  position: absolute;
  z-index: 20;
  max-width: calc(100% - 72px);
  margin: 0;
  border-radius: 12px;
  background-color: $codGray20;
  backdrop-filter: blur(14px);
  font-weight: 400;
  color: $white;

  @include respond-to(mobile) {
    bottom: 18px;
    left: 18px;
    padding: 12px 18px;
    font-size: 14px;
    line-height: 22px;
  }

  @include respond-to(tablet) {
    left: 25px;
    bottom: 20px;
    padding: 17px 22px;
    font-size: 20px;
    line-height: 24px;
  }

  @include respond-to(desktop) {
    left: 36px;
    bottom: 30px;
    padding: 25px 28px;
    font-size: 24px;
    line-height: 29px;
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 24px;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s;
  z-index: 0;
  &:hover {
    transform: scale(1.1);
    z-index: 5;
  }
}
