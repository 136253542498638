@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/originalHerbWormwoodLine.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 11%;
    margin-top: 47%;
    position: absolute;
  }

  .secondPopover {
    margin-left: 21%;
    margin-top: 32%;
    position: absolute;

    .popover {
      margin-bottom: -15px;
    }
  }

  .thirdPopover {
    margin-left: 30%;
    margin-top: 52%;
    position: absolute;
  }

  .fourthPopover {
    margin-left: 36%;
    margin-top: 29%;
    position: absolute;

    .popover {
      margin-bottom: -70px;
    }
  }

  .fifthPopover {
    margin-left: 39%;
    margin-top: 57%;
    position: absolute;
  }

  .sixthPopover {
    margin-left: 48%;
    margin-top: 49%;
    position: absolute;
  }
  
  .seventhPopover {
    margin-left: 58%;
    margin-top: 27%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        margin-left: -14px;
      }
    }
  }
  
  .eighthPopover {
    margin-left: 62%;
    margin-top: 38%;
    position: absolute;
  }
  
  .ninthPopover {
    margin-left: 66%;
    margin-top: 54%;
    position: absolute;
  }

  .tenthPopover {
    margin-left: 75%;
    margin-top: 29%;
    position: absolute;
  }

  .eleventhPopover {
    margin-left: 80%;
    margin-top: 45%;
    position: absolute;
  }
}
