@import "../../../../theme/index";

.container {
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: url('../../../../assets/images/fraijourMain/productLine/retineCollagenLine.jpg') no-repeat;
  background-size: 365px 264px;
  animation: show-block 0.5s ease-in-out;

  @keyframes show-block {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
  }

  @include respond-to(tablet) {
    background-size: 719px 555px;
  }

  @include respond-to(desktop) {
    background-size: 875px 675px;
  }

  @include respond-to(largeDesktop) {
    background-size: 1140px 875px;
  }

  .popoverButton {
    display: none;
    @include respond-to(tablet){
      display: block;
    }
  }

  .poiner {
    display: block;
    @include respond-to(tablet) {
      display: none;
    }
  }

  .firstPopover {
    margin-left: 2%;
    margin-top: 38%;
    position: absolute;
  }

  .secondPopover {
    margin-left: 15%;
    margin-top: 42%;
    position: absolute;
  }

  .thirdPopover {
    margin-left: 29%;
    margin-top: 48%;
    position: absolute;
  }

  .fourthPopover {
    margin-left: 43%;
    margin-top: 24%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -34px;
      }
    }
  }

  .fifthPopover {
    margin-left: 52%;
    margin-top: 48%;
    position: absolute;

    .popover {
      @include respond-to(tablet) {
        bottom: -10px;
      }
    }
  }

  .sixthPopover {
    margin-left: 68%;
    margin-top: 26%;
    position: absolute;
  }

  .seventhPopover {
    margin-left: 81%;
    margin-top: 50%;
    position: absolute;
  }

  .eighthPopover {
    margin-left: 86%;
    margin-top: 29%;
    position: absolute;
  }
}
