@import "../../../theme/index.scss";

.benefit {
    width: 100%;
    margin-top: 44px;

    &:first-child {
        margin-top: 50px;
    }

    @include respond-to(tablet) {
        width: 455px;
        margin-top: 140px;

        &:first-child {
            margin-top: 161px;
        }
    }

    @include respond-to(desktop) {
        width: 663px;
        margin-top: 190px;

        &:first-child {
            margin-top: 207px;
        }
    }
}

@mixin coloredTitle {
    @include respond-to(tablet) {
        color: $balticSea;
    }
}

.benefitTitle {
    width: 100%;
    margin: 0px;
    padding-bottom: 20px;
    font-size: 34px;
    font-weight: 400;
    line-height: 38px;

    border-bottom: 1px solid $balticSea;

    &.heartLeafMobile {
        color: $greenSmoke;
        @include coloredTitle;
    }

    &.originalArtemisiaMobile {
        color: $aquaForest;
        @include coloredTitle;
    }

    &.originalHerbWormwoodMobile {
        color: $aquaForest;
        @include coloredTitle;
    }

    &.yuzuHoneyMobile {
        color: $ronchi;
        @include coloredTitle;
    }

    &.retinCollagen3dCoreMobile {
        color: $portage;
        @include coloredTitle;
    }

    &.proMoistureMobile {
        color: $lightPortage;
        @include coloredTitle;
    }

    &.biome5lactoMobile {
        color: $newYorkPink;
        @include coloredTitle;
    }

    @include respond-to(tablet) {
        font-size: 40px;
        line-height: 50px;


        &.heartLeaf {
            color: $greenSmoke;
        }

        &.originalArtemisia {
            color: $aquaForest;
        }

        &.originalHerbWormwood {
            color: $aquaForest;
        }

        &.yuzuHoney {
            color: $ronchi;
        }

        &.retinCollagen3dCore {
            color: $portage;
        }

        &.proMoisture {
            color: $lightPortage;
        }

        &.biome5lacto {
            color: $newYorkPink;
        }
    }

    @include respond-to(desktop) {
        font-size: 60px;
        line-height: 90px;
        letter-spacing: -0.8px;
    }
}

.benefitDescription {
    padding-top: 20px;
    color: $santasGray;
    margin: 0px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    @include respond-to(tablet) {
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
    }

    @include respond-to(desktop) {
        font-size: 22px;
        line-height: 32px;
    }
}