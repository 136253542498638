@import '../../theme/index';

.container {
    width: 100%;
    height: auto;
    padding-bottom: 100px;

    @include respond-to(tablet) {
        height: 950px;
    }

    @include respond-to(desktop) {
        height: 1416px;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 15px;
    display: flex;
    flex-direction: column;

    @include respond-to(tablet) {
        padding: 0px 32px;
        flex-direction: row;
        justify-content: space-between;
    }

    @include respond-to(desktop) {
        padding: 0px 57px;
    }

    @include respond-to(largeDesktop) {
        padding: 0px 260px;
    }
}

.side {
    padding: 60px 20px 0px 0px;
    width: 100%;

    @include respond-to(tablet) {
        position: sticky;
        top: 0px;
        width: 475px;
        height: 180px;
        padding: 159px 20px 100px 0px;
    }

    @include respond-to(desktop) {
        width: 663px;
        height: 370px;
        padding: 202px 20px 100px 0px;
    }
}

.title {
    margin: 0px;
    font-size: 34px;
    font-weight: 400;
    line-height: 37px;
    color: $balticSea;

    @include respond-to(tablet) {
        font-size: 50px;
        line-height: 50px;
    }

    @include respond-to(desktop) {
        font-size: 80px;
        line-height: 90px;
        letter-spacing: -0.8px;
    }

}

.description {
    color: $balticSea;
    margin: 20px 0px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    @include respond-to(desktop) {
        margin: 40px 0px 0px;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
    }
}
