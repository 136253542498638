@import '../../theme/index';

.container {
    height: 100vh;
    min-height: 812px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    @include respond-to(tablet) {
        height: 750px;
        min-height: auto;
    }

    @include respond-to(desktop) {
        height: 1220px;
    }

    .blockCheckScroll {
        height: 5px;
        width: 100%;
        position: absolute;
        top: 100vh;
    }

    .background {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &Scrolled {
        .background {
            @include respond-only(mobile) {
                filter: blur(60px);
                backdrop-filter: blur(60px);
                -webkit-filter: blur(60px);
                transition: filter 0.1s linear;
            }
        }

        .title {
            @include respond-to(tablet) {
                font-size: 40px;
                line-height: 40px;
            }

            @include respond-to(desktop) {
                font-size: 60px;
                line-height: 60px;
            }
        }

        .description {
            opacity: 1;

            @include respond-to(tablet) {
                margin-top: 64px;
            }

            @include respond-to(desktop) {
                margin-top: 86px;
            }
        }
    }
}

.content {
    width: 100%;
    box-sizing: border-box;
    padding: 100px 15px 0px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100%;
    position: relative;

    @include respond-to(tablet) {
        flex-direction: row;
        justify-content: space-between;
        padding: 105px 32px 0px;
        position: sticky;
        top: 0px;
    }

    @include respond-to(desktop) {
        padding: 110px 57px 0px;
    }

    @include respond-to(largeDesktop) {
        padding: 110px 260px 0px;
    }
}

.buttonBack {
    width: 109px;
    height: 42px;
    padding: 8px 17px;
    border-radius: 20px;
    opacity: 0.7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $santasGray;
    background-color: $white50;
    &:hover:not(:disabled) {
        background-color: $white;
    }

    .iconBack {
        width: 14px;

        >path {
            fill: $balticSea;
        }
    }

    &:hover {
        opacity: 1;
    }

    @include respond-to(tablet) {
        align-items: baseline;
        width: 90px;
        height: 30px;
        padding: 5px 10px;
    }

    @include respond-to(desktop) {
        width: 115px;
        height: 39px;
        padding: 8px 23px;
        font-size: 16px;
        line-height: 23px;
    }
}

.subtitle {
    margin: 18px 0px 10px;
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    color: $balticSea;

    @include respond-to(tablet) {
        margin: 33px 0px 15px;
        font-size: 20px;
    }

    @include respond-to(desktop) {
        margin: 47px 0px 15px;
        font-size: 24px;
        line-height: 29px;
    }
}

.titleBlock {
    width: auto;
    height: auto;

    @include respond-to(tablet) {
        width: 465px;
        height: 120px;
    }

    @include respond-to(desktop) {
        width: 815px;
        height: 200px;
    }
}

.title {
    font-size: 40px;
    font-weight: 400;
    line-height: 44px;
    margin: 0px;
    transition: font-size 0.3s linear,
        line-height 0.3s linear;

    @include respond-to(tablet) {
        font-size: 60px;
        line-height: 60px;
    }

    @include respond-to(desktop) {
        font-size: 100px;
        line-height: 100px;
    }
}

.buttonDownBlock {
    position: absolute;
    bottom: 30px;
    width: calc(100% - 30px);
    display: flex;
    justify-content: center;
    @include respond-to(tablet) {
        width: auto;
        justify-content: flex-start;
    }
    @include respond-to(desktop) {
        bottom: 45px;
    }

    @include respond-to(largeDesktop) {
        bottom: 110px;
    }
}

.buttonDown {
    height: 60px;
    width: 60px;

    .iconDown {
        height: 30px;
    }

    @include respond-to(desktop) {
        height: 94px;
        width: 94px;
    }
}

.description {
    transition: margin-top 0.3s linear,
        opacity 0.3s linear;
    margin-top: 17px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    width: auto;
    opacity: 0;

    @include respond-to(tablet) {
        margin-top: 178px;
        width: 320px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        opacity: 1;
    }

    @include respond-to(desktop) {
        margin-top: 330px;
        width: 411px;
        font-size: 18px;
        line-height: 26px;
    }

    @include respond-to(largeDesktop) {
        width: 433px;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
    }
}

.pills {
    overflow: hidden;
    height: inherit;
    position: absolute;
    top: 0;
    width: 100%;

    &.active {
        display: none;
    }

    @include respond-to(tablet) {
        width: 800px;
        &.active {
            display: block;
        }
    }

    @include respond-to(desktop) {
        width: 850px;
    }
}

.pill1 {
    position: absolute;
    transform: rotateZ(-20deg);
    transition: 1s linear;
    top: 420px;
    left: -20px;
    color: $black;

    @include respond-to(tablet) {
        top: 383px;
        left: 38px;
    }

    @include respond-to(desktop) {
        top: 380px;
        left: 100px;
    }

    &.active {
        transform: rotateZ(15deg);
        transition: 1s linear;

        @include respond-to(tablet) {
            top: 5px;
            left: 80px;
        }

        @include respond-to(desktop) {
            top: 28px;
            left: 100px;
        }
    }
}

.pill2 {
    position: absolute;
    transition: 1s linear;
    transform: rotateZ(-25deg);
    top: 550px;
    right: -14px;
    color: $black;

    @include respond-to(tablet) {
        top: 512px;
        right: 80px;
        transform: rotateZ(25deg);
    }

    @include respond-to(desktop) {
        top: 650px;
        right: 55px;
    }

    &.active {
        transform: rotateZ(-16deg);
        transition: 1s linear;

        @include respond-to(tablet) {
            top: 25px;
            right: 115px;
        }

        @include respond-to(desktop) {
            top: 40px;
            right: 50px;
        }
    }
}

.pill3 {
    position: absolute;
    transition: 1s linear;
    transform: rotateZ(30deg);
    top: 570px;
    left: -40px;
    color: $black;

    @include respond-to(tablet) {
        transform: rotateZ(60deg);
        top: 680px;
        left: 100px;
    }

    @include respond-to(desktop) {
        top: 700px;
        left: 100px;
    }

    &.active {
        transform: rotateZ(-16deg);
        transition: 1s linear;

        @include respond-to(tablet) {
            top: 280px;
            left: 70px;
        }

        @include respond-to(desktop) {
            top: 430px;
            left: 100px;
        }
    }
}
