.container {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;

  .nextStory {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .previousStory {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
}